import React from "react";
import PropTypes from "prop-types";
import { Field, Form, Formik } from "formik";
import { pick } from "lodash";
import { useMutation } from "@apollo/react-hooks";

import {
  FieldError,
  FormResponse,
  validate
} from "../../../../components/FormikHelpers";

import { SAVE_PROFILE_SETTINGS } from "../../../../queries";

const propTypes = {
  profile: PropTypes.object.isRequired
};

const ProfileSettingsForm = props => {
  const { profile } = props;

  const [saveProfileSettings] = useMutation(SAVE_PROFILE_SETTINGS);

  const handleSubmit = async (values, formik) => {
    const { resetForm, setStatus, setSubmitting } = formik;

    try {
      await saveProfileSettings({
        variables: {
          profileId: profile._id,
          data: values
        }
      });
      resetForm();
      setStatus({ success: "Your settings are saved successfully!" });
    } catch (err) {
      setStatus({ err });
    }

    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={pick(profile, ["companyName", "fullName", "email"])}
      validate={values => {
        return validate(values, [
          "companyName",
          "fullName",
          {
            key: "email",
            label: "email",
            validation: "email"
          }
        ]);
      }}
      onSubmit={handleSubmit}
      render={formik => {
        const { isSubmitting, isValid } = formik;

        return (
          <Form>
            <label htmlFor="companyName">Company name</label>
            <Field
              type="text"
              name="companyName"
              placeholder="Your company name"
              required
            />
            <FieldError field="companyName" />

            <label htmlFor="fullName">Name</label>
            <Field
              type="text"
              name="fullName"
              placeholder="Your name"
              required
            />
            <FieldError field="fullName" />

            <label htmlFor="email">Email</label>
            <Field
              type="email"
              name="email"
              placeholder="Your email"
              required
            />
            <FieldError field="email" />

            <FormResponse />

            <button
              className="button button-gray"
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              Save
            </button>
          </Form>
        );
      }}
    />
  );
};

ProfileSettingsForm.propTypes = propTypes;

export default ProfileSettingsForm;
