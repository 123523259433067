import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Field, Form, Formik } from "formik";
import queryString from "query-string";
import { withRouter } from "react-router-dom";

import { FieldError, validate } from "../../../../components/FormikHelpers";

const propTypes = {
  location: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};

const StepThree = props => {
  const { location, onSubmit } = props;

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const queryParams = queryString.parse(location.search);
  const email = queryParams.email || "";

  useEffect(() => {
    if (email) {
      passwordRef.current.focus();
    } else {
      emailRef.current.focus();
    }
  }, [email]);

  return (
    <Formik
      initialValues={{
        email,
        password: ""
      }}
      validate={values => {
        return validate(values, [
          { key: "email", label: "email", validation: "email" },
          "password"
        ]);
      }}
      onSubmit={onSubmit}
      validateOnBlur={false}
      render={formik => {
        const { isSubmitting, isValid } = formik;

        return (
          <Form>
            <h2 className="h3 text-primary-500 leading-tight">
              Last, just set your email and password
            </h2>

            <label htmlFor="email">Email</label>
            <Field
              innerRef={emailRef}
              type="email"
              name="email"
              placeholder="Your email address"
              required
            />
            <FieldError field="email" />

            <label htmlFor="password">Password</label>
            <Field
              innerRef={passwordRef}
              type="password"
              name="password"
              placeholder="Your password"
              required
            />
            <FieldError field="password" />

            <button
              className="button button-primary"
              disabled={!isValid || isSubmitting}
              type="submit"
            >
              All Done!
            </button>
          </Form>
        );
      }}
    />
  );
};

StepThree.propTypes = propTypes;

export default withRouter(StepThree);
