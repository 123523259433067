import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  size: PropTypes.number
};

const defaultProps = {
  size: 24
};

const Underline = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="align-middle"
    >
      <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon>
    </svg>
  );
};

Underline.propTypes = propTypes;
Underline.defaultProps = defaultProps;

export default Underline;
