import React from "react";
import PropTypes from "prop-types";
import { Field, Form, Formik } from "formik";
import { resetPassword } from "meteor-apollo-accounts";

import apolloClient from "../apollo";
import {
  FieldError,
  FormResponse,
  validate
} from "../components/FormikHelpers";
import { useStartRef } from "../helpers";

const propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

const SetPassword = props => {
  const { history, match } = props;

  const startRef = useStartRef(null);

  const { token } = match.params;

  const setPassword = async (values, formik) => {
    const { setStatus, setSubmitting } = formik;
    const { newPassword } = values;

    try {
      await resetPassword({ token, newPassword }, apolloClient);
      history.push("/");
    } catch (err) {
      setStatus({ err });
    }

    setSubmitting(false);
  };

  return (
    <div className="container-sm">
      <h1 className="text-center h2">Set your new password</h1>
      <div className="callout p-8 ">
        <Formik
          initialValues={{
            newPassword: ""
          }}
          validate={values => {
            return validate(values, ["newPassword"]);
          }}
          onSubmit={setPassword}
          validateOnBlur={false}
          render={formik => {
            const { isSubmitting, isValid } = formik;

            return (
              <Form>
                <Field
                  innerRef={startRef}
                  type="password"
                  name="newPassword"
                  placeholder="Password"
                />
                <FieldError field="newPassword" />

                <FormResponse />

                <button
                  className="button button-primary"
                  type="submit"
                  disabled={isSubmitting || !isValid}
                >
                  Set password
                </button>
              </Form>
            );
          }}
        />
      </div>
    </div>
  );
};

SetPassword.propTypes = propTypes;

export default SetPassword;
