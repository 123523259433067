import React from 'react';

import { LinkIcon } from '../../../../Icons';

const InlineButton = (props) => {
  const { editor, type, value } = props;

  const isActive = value.inlines.some((inline) => {
    return inline.type === type;
  });

  return (
    <div>
      <button
        className={`focus:outline-none p-2 ${isActive ? 'text-black' : 'text-grey'}`}
        type="button"
        onMouseDown={() => {
          if (isActive) {
            editor.unwrapInline(type);
          } else {
            editor.wrapInline({
              type,
              data: {
                href: 'https://www.example.com',
              },
            });
          }
        }}
      >
        {type === 'link' && <LinkIcon size={18} />}
      </button>
    </div>
  );
};

export default InlineButton;
