import React from 'react';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { logout } from 'meteor-apollo-accounts';

import Bubble from '../../components/Bubble';
import FeatureLock from '../../components/FeatureLock';

import { GET_ME } from '../../queries';
import apolloClient from '../../apollo';

import MessengerSettingsForm from './components/MessengerSettingsForm';
import ProfileSettingsForm from './components/ProfileSettingsForm';
import ResponseSettingsForm from './components/ResponseSettingsForm';
import SendingDomainsForm from './components/SendingDomainsForm';

const Settings = (props) => {
  const { history } = props;

  const { loading, data } = useQuery(GET_ME);
  const profile = !loading && data && data.me;

  if (!profile) return false;

  const handleLogout = async () => {
    try {
      await logout(apolloClient);
      history.push('/sign-in');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className="container-md">
        <div className="callout mb-6">
          <h2 className="h4">Profile settings</h2>
          <ProfileSettingsForm profile={profile} />
        </div>

        <div className="callout mb-6">
          <h2 className="h4">Subscription</h2>
          Plan:
          {' '}
          {capitalize(profile.plan)}
          {profile.plan === 'free' && (
            <p className="mt-4">
              <Link to="/upgrade" className=" button button-primary">
                Go PRO
              </Link>
              <span className="text-gray-600">
                <span className="font-bold pl-2">US$ 9,- p/m</span>
                {' '}
- Cancel
                Anytime - Moneback guarantee.
              </span>
            </p>
          )}
        </div>

        <div className="callout mb-6">
          <h2 className="h4">Messenger settings</h2>
          <MessengerSettingsForm profile={profile} />
        </div>

        <div className="callout mb-6">
          <h2 className="h4">
            Sending domains
            {profile.plan === 'free' && <FeatureLock />}
          </h2>
          <SendingDomainsForm profile={profile} />
        </div>

        <div className="callout mb-6">
          <h2 className="h4">Response settings</h2>
          <ResponseSettingsForm profile={profile} />
        </div>

        <div className="callout mb-6">
          <button
            type="button"
            className="button button-gray"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </div>
      <div className="fixed bottom-0 right-0 mr-24 mb-8 text-grey-dark bg-white shadow-lg rounded-full py-2 px-4">
        <code>Preview of your bubble</code>
        {' '}
→
      </div>
      <Bubble isTesting profileId={profile._id} />
    </>
  );
};

export default Settings;
