import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { createUser } from 'meteor-apollo-accounts';
import amplitude from 'amplitude-js';

import apolloClient from '../../apollo';
import { FormResponse } from '../../components/FormikHelpers';

import StepOne from './components/StepOne';
import StepThree from './components/StepThree';
import StepTwo from './components/StepTwo';

const propTypes = {
  history: PropTypes.object.isRequired,
};

const SignUp = (props) => {
  const { history } = props;
  const [stepIndex, setStepIndex] = useState(0);
  const [allValues, setValues] = useState({});

  const handleSignUp = async (values, formik) => {
    const { setStatus, setSubmitting } = formik;

    const newValues = { ...allValues, ...values };
    setValues(newValues);

    const { email, password, ...profile } = newValues;

    try {
      await createUser({ email, password, profile }, apolloClient);

      amplitude.getInstance().logEvent('profiles.signedUp');
      history.push('/welcome');
    } catch (err) {
      setStatus({ err });
    }

    setSubmitting(false);
  };

  const handleProceed = (values) => {
    const newValues = { ...allValues, ...values };
    setValues(newValues);

    setStepIndex(stepIndex + 1);
  };

  return (
    <div className="container">
      <h1 className="h4 text-center text-gray-600 leading-tight">
        Just a few quick things
        {' '}
        <br />
        to set up your account…
      </h1>
      <div className="container-md">
        <div className="callout p-8">
          {stepIndex === 0 && <StepOne onSubmit={handleProceed} />}
          {stepIndex === 1 && <StepTwo onSubmit={handleProceed} />}
          {stepIndex === 2 && <StepThree onSubmit={handleSignUp} />}

          <FormResponse />
        </div>
        <div className="text-center">
          <Link to="/sign-in">Already have an account? Sign in instead</Link>
        </div>
      </div>
    </div>
  );
};

SignUp.propTypes = propTypes;

export default SignUp;
