import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import EmbedCode from '../components/EmbedCode';


import { GET_ME } from '../queries';

const Integrate = (props) => {
  const { isFirstVisit } = props;

  const { loading, data } = useQuery(GET_ME);
  const profile = !loading && data && data.me;

  if (!profile) return false;

  return (
    <div className="container-md">
      <div className="callout">
        {isFirstVisit && (
          <h1 className="h3">{`Welcome ${profile.firstName}!`}</h1>
        )}
        <h2 className="h4">Now embed your ContactBubble on your website:</h2>
        <p>
          Copy the following code before the closing
          {' '}
          {'</body>'}
          {' '}
tag on every
          page you want the Contactbubble to appear on your website.
        </p>

        <EmbedCode
          code={[
            '<script>',
            '  window.contactbubbleSettings = {',
            `    app_id: "${profile._id}",`,
            '}',
            '</script>',
            `<script type="text/javascript" src="${process.env.REACT_APP_CLIENT_URL}/embed.js" defer></script>`,
          ].join('\n')}
        />

        <hr />

        <h2 className="h5">Additional data</h2>
        <p>
          Note. If you have logged in users, you can add their
          {' '}
          <code>name</code>
          ,
          {' '}
          <code>email</code>
          {' '}
and
          {' '}
          <code>customFields</code>
          {' '}
(object) properties
          to identify these users. You would need to add the following
          properties to the
          {' '}
          <code>contactbubbleSettings</code>
          {' '}
object:
        </p>

        <EmbedCode
          code={[
            '<script>',
            '  window.contactbubbleSettings = {',
            `    app_id: "${profile._id}",`,
            '    name: "Michael Bluth", // Sets your user\'s name',
            '    email: "michael@thebluthcompany.com", // Sets your user\'s email',
            '    customFields: {',
            '      plan: "pro", // Sets your user\'s plan property',
            '    },',
            '}',
            '</script>',
          ].join('\n')}
        />
      </div>
    </div>
  );
};

export default Integrate;
