import React, { useRef, useState } from 'react';
import { connect } from 'formik';
import { Editor } from 'slate-react';
import { isKeyHotkey } from 'is-hotkey';

import BlockButton from './components/BlockButton';
import InlineButton from './components/InlineButton';
import LinkNode from './components/LinkNode';
import MarkButton from './components/MarkButton';

import { html } from './helpers';

const isBoldHotkey = isKeyHotkey('mod+b');
const isItalicHotkey = isKeyHotkey('mod+i');
const isUnderlinedHotkey = isKeyHotkey('mod+u');
const isCodeHotkey = isKeyHotkey('mod+`');

const EditorField = (props) => {
  const { disabled, formik, name } = props;
  const { setFieldValue, values } = formik;

  const [value, setValue] = useState(html.deserialize(values[name] || '<p>Test me baby</p>'));
  const editorNode = useRef(null);
  const editor = editorNode.current;

  const handleChange = (result) => {
    if (result.value.document !== value.document) {
      setFieldValue(name, html.serialize(result.value));
    }

    setValue(result.value);
  };

  const handleKeyDown = (ev, editor, next) => {
    const getMark = () => {
      if (isBoldHotkey(ev)) {
        return 'bold';
      } if (isItalicHotkey(ev)) {
        return 'italic';
      } if (isUnderlinedHotkey(ev)) {
        return 'underlined';
      } if (isCodeHotkey(ev)) {
        return 'code';
      }

      return false;
    };

    const mark = getMark();

    if (!mark) return next();

    ev.preventDefault();
    editor.toggleMark(mark);
  };


  return (
    <div className="border rounded mb-4">
      <div className={`flex border-b-2 ${disabled ? 'pointer-events-none' : ''}`}>
        <MarkButton editor={editor} type="bold" value={value} />
        <MarkButton editor={editor} type="italic" value={value} />
        <MarkButton editor={editor} type="underlined" value={value} />
        <MarkButton editor={editor} type="code" value={value} />
        <InlineButton editor={editor} type="link" value={value} />
        <BlockButton editor={editor} type="heading-one" value={value} />
        <BlockButton editor={editor} type="heading-two" value={value} />
        <BlockButton editor={editor} type="heading-three" value={value} />
        <BlockButton editor={editor} type="block-quote" value={value} />
        <BlockButton editor={editor} type="bulleted-list" value={value} />
        <BlockButton editor={editor} type="numbered-list" value={value} />
      </div>
      <div className={`p-4 ${disabled ? 'bg-grey-lightest' : 'bg-white'}`}>
        {' '}
        <Editor
          readOnly={disabled}
          ref={editorNode}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          renderBlock={({ attributes, children, node }, editorCopy, next) => {
            switch (node.type) {
              case 'block-quote':
                return <blockquote {...attributes}>{children}</blockquote>;
              case 'bulleted-list':
                return <ul {...attributes}>{children}</ul>;
              case 'heading-one':
                return <h1 {...attributes}>{children}</h1>;
              case 'heading-two':
                return <h2 {...attributes}>{children}</h2>;
              case 'heading-three':
                return <h3 {...attributes}>{children}</h3>;
              case 'list-item':
                return <li {...attributes}>{children}</li>;
              case 'numbered-list':
                return <ol {...attributes}>{children}</ol>;
              case 'paragraph':
                return <p>{children}</p>;
              default:
                return next();
            }
          }}
          renderInline={({ attributes, children, node }, editor, next) => {
            switch (node.type) {
              case 'link':
                return (
                  <LinkNode attributes={attributes} node={node} editor={editor}>
                    {children}
                  </LinkNode>
                );
              default: {
                return next();
              }
            }
          }}
          renderMark={({ children, mark, attributes }, editor, next) => {
            switch (mark.type) {
              case 'bold':
                return <strong {...attributes}>{children}</strong>;
              case 'code':
                return <code {...attributes}>{children}</code>;
              case 'italic':
                return <em {...attributes}>{children}</em>;
              case 'underlined':
                return <u {...attributes}>{children}</u>;
              default:
                return next();
            }
          }}
        />
      </div>
    </div>
  );
};

export default connect(EditorField);
