import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Field, Form, Formik } from "formik";

import {
  ColorField,
  EditorField,
  FieldError,
  validate
} from "../../../../components/FormikHelpers";

const propTypes = {
  onSubmit: PropTypes.func.isRequired
};

const StepTwo = props => {
  const { onSubmit } = props;

  const startRef = useRef(null);

  useEffect(() => {
    startRef.current.focus();
  }, []);

  return (
    <Formik
      initialValues={{
        greetingText: "Hi {{firstName}} 👋",
        introText: "Ask us anything, or share your feedback",
        primaryColor: "#222222"
      }}
      validate={values => {
        return validate(values, ["greetingText", "introText", "primaryColor"]);
      }}
      isInitialValid
      onSubmit={onSubmit}
      validateOnBlur={false}
      render={formik => {
        const { isSubmitting, isValid } = formik;

        return (
          <Form>
            <h2 className="h3 text-primary-500 leading-tight">
              Tell us what text you want on the messenger
            </h2>

            <label htmlFor="greetingText">Greeting Text</label>
            <Field
              innerRef={startRef}
              type="text"
              name="greetingText"
              placeholder="Your greeting to users"
              required
            />
            <FieldError field="greetingText" />

            <label htmlFor="introText">Intro Text</label>
            <EditorField
              name="introText"
              placeholder="Your introduction text to users"
              required
            />
            <FieldError field="introText" />

            <label htmlFor="primaryColor">Color</label>
            <ColorField name="primaryColor" />

            <button
              className="button button-primary"
              disabled={!isValid || isSubmitting}
              type="submit"
            >
              Almost done →
            </button>
          </Form>
        );
      }}
    />
  );
};

StepTwo.propTypes = propTypes;

export default StepTwo;
