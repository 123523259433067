import React from 'react';
import PropTypes from 'prop-types';
import { renderToString } from 'react-dom/server';
import { render } from 'mustache';

import { en, nl } from '../intl';

const propTypes = {
  id: PropTypes.string.isRequired,
  language: PropTypes.string,
  values: PropTypes.object,
};

const defaultProps = {
  language: 'en',
  values: {},
};

const Message = (props) => {
  const { id, language, values } = props;

  const languages = {
    en,
    nl,
  };

  const message = languages[language][id] || en[id];
  const messageMustached = render(message, values);

  return messageMustached;
};

Message.propTypes = propTypes;
Message.defaultProps = defaultProps;

export default Message;

export const renderMessage = (id, language, values = {}) => {
  if (!id) return false;

  return renderToString(<Message id={id} language={language} values={values} />);
};
