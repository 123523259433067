import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  size: PropTypes.number
};

const defaultProps = {
  size: 24
};

const RefreshCCW = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="align-middle"
    >
      <polyline points="1 4 1 10 7 10"></polyline>
      <polyline points="23 20 23 14 17 14"></polyline>
      <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
    </svg>
  );
};

RefreshCCW.propTypes = propTypes;
RefreshCCW.defaultProps = defaultProps;

export default RefreshCCW;
