import React from 'react';

import { BulletedListIcon, NumberedListIcon, QuoteIcon } from '../../../../Icons';

const DEFAULT_NODE = 'paragraph';

const BlockButton = (props) => {
  const { editor, type, value } = props;

  const hasBlock = (type) => {
    return value.blocks.some((node) => {
      return node.type === type;
    });
  };

  let isActive = hasBlock(type);

  if (['numbered-list', 'bulleted-list'].includes(type)) {
    const { document, blocks } = value;

    if (blocks.size > 0) {
      const parent = document.getParent(blocks.first().key);
      isActive = hasBlock('list-item') && parent && parent.type === type;
    }
  }

  return (
    <div>
      <button
        className={`focus:outline-none p-2 ${isActive ? 'text-black' : 'text-grey'}`}
        type="button"
        onMouseDown={(ev) => {
          ev.preventDefault();

          const { document } = value;

          // Handle everything but list buttons.
          if (type !== 'bulleted-list' && type !== 'numbered-list') {
            const isActive = hasBlock(type);
            const isList = hasBlock('list-item');

            if (isList) {
              editor
                .setBlocks(isActive ? DEFAULT_NODE : type)
                .unwrapBlock('bulleted-list')
                .unwrapBlock('numbered-list');
            } else {
              editor.setBlocks(isActive ? DEFAULT_NODE : type);
            }
          } else {
            // Handle the extra wrapping required for list buttons.
            const isList = hasBlock('list-item');
            const isType = value.blocks.some((block) => {
              return !!document.getClosest(block.key, (parent) => {
                return parent.type === type;
              });
            });

            if (isList && isType) {
              editor
                .setBlocks(DEFAULT_NODE)
                .unwrapBlock('bulleted-list')
                .unwrapBlock('numbered-list');
            } else if (isList) {
              editor
                .unwrapBlock(type === 'bulleted-list' ? 'numbered-list' : 'bulleted-list')
                .wrapBlock(type);
            } else {
              editor.setBlocks('list-item').wrapBlock(type);
            }
          }
        }}
      >
        {type === 'block-quote' && <QuoteIcon />}
        {type === 'bulleted-list' && <BulletedListIcon />}
        {type === 'heading-one' && <div className="font-semibold">H1</div>}
        {type === 'heading-two' && <div className="font-semibold">H2</div>}
        {type === 'heading-three' && <div className="font-semibold">H3</div>}
        {type === 'numbered-list' && <NumberedListIcon />}
      </button>
    </div>
  );
};

export default BlockButton;
