import React from "react";
import PropTypes from "prop-types";

import { TrashIcon } from "../../../../../Icons";

import { getBase64File } from "./helpers";

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object
};

const defaultProps = {
  value: undefined
};

const FileField = props => {
  const { children, onChange, name, value } = props;

  const handleChange = ev => {
    const { files } = ev.target;

    if (files && files.length) {
      getBase64File(files[0]).then(newFile => {
        onChange(name, newFile);
      });
    } else {
      onChange(name, undefined);
    }
  };

  const handleReset = () => {
    window.requestAnimationFrame(() => {
      onChange(name, undefined);
    });
  };

  return (
    <div>
      <input
        className="hidden"
        name={name}
        id={name}
        type="file"
        onChange={handleChange}
      />
      {value ? (
        <div className="text-gray-600 text-sm mb-4">
          {value.name}{" "}
          <button type="button" onClick={handleReset}>
            <TrashIcon size={16} />
          </button>
        </div>
      ) : (
        <label htmlFor={name} className="subtle-link block mb-4 cursor-pointer">
          {children}
        </label>
      )}
    </div>
  );
};

FileField.propTypes = propTypes;
FileField.defaultProps = defaultProps;

export default FileField;
