export const readDataUrl = (file, callback) => {
  const reader = new window.FileReader();

  reader.onloadend = function() {
    callback(reader.result);
  };

  reader.readAsDataURL(file);
};

export const getBase64File = (file) => {
  return new Promise((resolve) => {
    readDataUrl(file, (res) => {
      const { name, size, type } = file;

      resolve(
        Object.assign(
          {},
          {
            base64: res.split(',')[1],
            name,
            size,
            type,
          }
        )
      );
    });
  });
};
