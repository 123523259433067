import React from 'react';

import { BoldIcon, CodeIcon, ItalicIcon, LinkIcon, UnderlineIcon } from '../../../../Icons';

const MarkButton = (props) => {
  const { editor, type, value } = props;

  const isActive = value.activeMarks.some((mark) => {
    return mark.type === type;
  });

  return (
    <div>
      <button
        className={`focus:outline-none p-2 ${isActive ? 'text-black' : 'text-grey'}`}
        type="button"
        onMouseDown={() => {
          editor.toggleMark(type);
        }}
      >
        {type === 'bold' && <BoldIcon size={18} />}
        {type === 'code' && <CodeIcon size={18} />}
        {type === 'font' && <CodeIcon size={18} />}
        {type === 'italic' && <ItalicIcon size={18} />}
        {type === 'link' && <LinkIcon size={18} />}
        {type === 'underlined' && <UnderlineIcon size={18} />}
      </button>
    </div>
  );
};

export default MarkButton;
