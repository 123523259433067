import { useEffect, useRef } from 'react';

export const useStartRef = (ref) => {
  const startRef = useRef(ref);

  useEffect(() => {
    if (!startRef.current) return;

    startRef.current.focus();
  }, []);

  return startRef;
};

export const isAdmin = (email) => {
  if (process.env.NODE_ENV === 'development') return true;
  if (email === 'support@convertcalculator.co') return true;

  return false;
};
