import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  size: PropTypes.number,
};

const defaultProps = {
  size: 24,
};

const NumberedList = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      className="align-middle"
    >
      <g stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <line x1="8" y1="6" x2="21" y2="6" />
        <line x1="8" y1="12" x2="21" y2="12" />
        <line x1="8" y1="18" x2="21" y2="18" />
      </g>
      <g fill="currentColor">
        <path d="M3.08398 4.41016H2.5625L2.8125 3.98438H3.53906V7H3.08398V4.41016Z" />
        <path d="M3.00195 12.5723H4.125V13H2.09961L3.14648 11.7324C3.24935 11.6061 3.33529 11.4967 3.4043 11.4043C3.47461 11.3118 3.52799 11.2344 3.56445 11.1719C3.63867 11.0495 3.67578 10.9395 3.67578 10.8418C3.67578 10.7012 3.6263 10.5833 3.52734 10.4883C3.42839 10.3932 3.30599 10.3457 3.16016 10.3457C2.85547 10.3457 2.68099 10.526 2.63672 10.8867H2.18359C2.25651 10.2513 2.57747 9.93359 3.14648 9.93359C3.42122 9.93359 3.65169 10.0208 3.83789 10.1953C4.02539 10.3698 4.11914 10.5859 4.11914 10.8438C4.11914 11.0078 4.07552 11.1686 3.98828 11.3262C3.94401 11.4082 3.87891 11.5059 3.79297 11.6191C3.70833 11.7311 3.60091 11.8639 3.4707 12.0176L3.00195 12.5723Z" />
        <path d="M3.03516 17.5996V17.2109C3.22786 17.2109 3.35742 17.1895 3.42383 17.1465C3.54232 17.071 3.60156 16.9499 3.60156 16.7832C3.60156 16.6543 3.55859 16.5495 3.47266 16.4688C3.38802 16.3867 3.27799 16.3457 3.14258 16.3457C3.01628 16.3457 2.91862 16.3763 2.84961 16.4375C2.7832 16.5 2.73372 16.6055 2.70117 16.7539H2.24805C2.29492 16.4701 2.39128 16.2624 2.53711 16.1309C2.68294 15.998 2.88672 15.9316 3.14844 15.9316C3.41276 15.9316 3.62826 16.0098 3.79492 16.166C3.96159 16.321 4.04492 16.5208 4.04492 16.7656C4.04492 17.0417 3.93555 17.2565 3.7168 17.4102C3.9668 17.5573 4.0918 17.7962 4.0918 18.127C4.0918 18.3978 4.00065 18.6191 3.81836 18.791C3.63607 18.9629 3.40234 19.0488 3.11719 19.0488C2.75391 19.0488 2.48438 18.9154 2.30859 18.6484C2.23568 18.5365 2.18945 18.3815 2.16992 18.1836H2.60938C2.63281 18.3294 2.68945 18.4414 2.7793 18.5195C2.87044 18.5964 2.98893 18.6348 3.13477 18.6348C3.2819 18.6348 3.40365 18.5859 3.5 18.4883C3.59635 18.388 3.64453 18.2611 3.64453 18.1074C3.64453 17.9043 3.57227 17.7598 3.42773 17.6738C3.3431 17.6243 3.21224 17.5996 3.03516 17.5996Z" />
      </g>
    </svg>
  );
};

NumberedList.propTypes = propTypes;
NumberedList.defaultProps = defaultProps;

export default NumberedList;
