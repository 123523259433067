import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { render } from 'mustache';
import Reaptcha from 'reaptcha';
import readableColor from 'polished/lib/color/readableColor';
import useMobileDetect from 'use-mobile-detect-hook';

import Message, { renderMessage } from '../Message';
import {
  ArrowLeftIcon, CheckIcon, PaperclipIcon, SendIcon,
} from '../../../Icons';

import FileField from './components/FileField';
import FieldError from './components/FieldError';
import FormAlert from './components/FormAlert';

import { validate } from './helpers';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

const defaultProps = {
  isOpen: false,
};

const Form = (props) => {
  const {
    email,
    emailRef,
    errorMessage,
    isOpen,
    isSubmitted,
    isSubmitting,
    messageRef,
    model,
    name,
    nameRef,
    onReset,
    onSubmit,
    profile,
    status,
  } = props;
  const { formResponseText, language, primaryColor } = profile;

  const recaptchaComponent = useRef(null);

  const detectMobile = useMobileDetect();

  const [fields, setFields] = useState({
    name,
    email,
    message: '',
    file: undefined,
  });
  const [touched, setTouched] = useState({
    name: false,
    email: false,
    message: false,
    file: false,
  });
  const [errors, setErrors] = useState(validate(fields));

  const [isVerified, setVerified] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  const isValid = Object.keys(errors).length === 0;

  const formResponseTextMustached = render(formResponseText, model);

  const handleChange = (changeName, value) => {
    const newFields = { ...fields, [changeName]: value };
    setFields(newFields);

    const newTouched = { ...touched, [changeName]: true };
    setTouched(newTouched);

    const newErrors = validate(newFields);
    setErrors(newErrors);
  };

  const handleChangeNativeInput = (ev) => {
    const { name: changeName, value } = ev.target;

    handleChange(changeName, value);
  };

  const handleReset = () => {
    setVerified(false);

    setFields({
      name,
      email,
      message: '',
      file: undefined,
    });
    setTouched({
      name: false,
      email: false,
      message: false,
      file: false,
    });
    setErrors({});

    recaptchaComponent.current.reset();
  };

  const handleSendAnotherMessage = () => {
    handleReset();
    onReset();
  };

  const handleSubmit = () => {
    onSubmit(fields, (err) => {
      if (!err) {
        handleReset();
      }
    });
  };

  useEffect(() => {
    if (!isVerified) return;

    handleSubmit();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVerified]);

  const handleVerify = (ev) => {
    ev.preventDefault();

    if (!recaptchaComponent.current) return;

    setIsVerifying(true);

    recaptchaComponent.current.execute();
  };

  const handleVerification = (recaptcha) => {
    if (!recaptcha) return;

    setVerified(true);
    setIsVerifying(false);
  };

  const isLoading = isVerifying || isSubmitting;

  return (
    <form onSubmit={handleVerify}>
      <div style={{ display: isSubmitted ? 'block' : 'none' }}>
        <button
          className="subtle-link text-sm mb-4 block"
          type="button"
          onClick={handleSendAnotherMessage}
        >
          <ArrowLeftIcon size={16} />
          {' '}
          <Message language={language} id="formResponseButtonText" />
        </button>

        <div className="callout bg-gray-100 border mb-6">
          {!profile.shouldShowCustomFormResponse && (
            <>
              <div className="text-left">
                <div
                  className="inline-block rounded-full w-12 h-12 text-white p-2 text-center mb-2"
                  style={{
                    backgroundColor: profile.successColor,
                  }}
                >
                  <CheckIcon size={36} className="leading-none" />
                </div>
              </div>

              <p>
                Thanks! Let's give
                {' '}
                {profile.companyName}
                {' '}
the time they need to respond properly.
              </p>

              <p className="text-gray-500 text-sm mb-0">
                Do you have a website and want to communicate with your customers with ease,
                {' '}
                <a
                  href="https://www.contactbubble.com?ref=bubble-thankyou"
                  className="underline text-gray-500"
                >
                  try ContactBubble today
                </a>
                .
              </p>
            </>
          )}

          {profile.shouldShowCustomFormResponse && (
            <div
              className="no-margin-last-child"
              dangerouslySetInnerHTML={{ __html: formResponseTextMustached }}
            />
          )}
        </div>
      </div>

      <div style={{ display: !isSubmitted ? 'block' : 'none' }} className="py-6">
        <input
          ref={nameRef}
          name="name"
          type="text"
          placeholder={`${renderMessage('nameFieldPlaceholder', language)} *`}
          hidden={!!name}
          style={{ display: name ? 'none' : 'block' }}
          onChange={handleChangeNativeInput}
          value={fields.name}
        />
        <FieldError
          alertColor={profile.alertColor}
          touched={touched.name}
          error={renderMessage(errors.name, language)}
        />
        <input
          ref={emailRef}
          name="email"
          type="email"
          placeholder={`${renderMessage('emailFieldPlaceholder', language)} *`}
          hidden={!!email}
          onChange={handleChangeNativeInput}
          value={fields.email}
        />
        <FieldError
          alertColor={profile.alertColor}
          touched={touched.email}
          error={renderMessage(errors.email, language)}
        />
        <textarea
          className="mb-0"
          ref={messageRef}
          name="message"
          id="message"
          rows="5"
          placeholder={`${renderMessage('messageFieldPlaceholder', language)} *`}
          onChange={handleChangeNativeInput}
          value={fields.message}
        />
        <FieldError
          alertColor={profile.alertColor}
          touched={touched.message}
          error={renderMessage(errors.message, language)}
        />

        <FileField name="file" value={fields.file} onChange={handleChange}>
          <PaperclipIcon size={16} />
        </FileField>

        <FormAlert alertColor={profile.alertColor} errorMessage={errorMessage} status={status} />

        <button
          className="button mt-2 mb-4"
          type="submit"
          disabled={isLoading || !isValid}
          style={{
            backgroundColor: primaryColor,
            color: readableColor(primaryColor, '#2d3748', '#f7fafc'),
          }}
        >
          {isLoading ? (
            <Message language={language} id="formSubmitLoadingText" />
          ) : (
            <>
              <span className="mr-2">
                <SendIcon size={16} />
              </span>
              <Message language={language} id="formSubmitText" />
            </>
          )}
        </button>

        {isOpen && (
          <Reaptcha
            ref={recaptchaComponent}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onVerify={handleVerification}
            size="invisible"
            badge={detectMobile.isMobile() ? 'inline' : 'bottomleft'}
          />
        )}
      </div>
    </form>
  );
};

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

export default Form;
