export default {
  attachmentLabel: 'Voeg bijlage toe',
  formResponseButtonText: 'Verstuur nog een bericht',
  formSubmitLoadingText: 'Laden...',
  formSubmitText: 'Verstuur bericht',
  nameFieldError: 'Een naam is verplicht',
  nameFieldPlaceholder: 'Naam',
  emailFieldError: 'Een geldig email addres is verplicht',
  emailFieldPlaceholder: 'E-mailadres',
  messageFieldError: 'Een bericht is verplicht',
  messageFieldPlaceholder: 'Bericht',
};
