import React from "react";
import PropTypes from "prop-types";
import { connect } from "formik";
import { get } from "lodash";

const propTypes = {
  field: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired
};

const FieldError = props => {
  const { formik, field } = props;
  const { errors, touched } = formik;

  if (!get(touched, field) || !get(errors, field)) return false;

  return <div className="text-red-400 text-sm mb-4 -mt-4">{errors[field]}</div>;
};

FieldError.propTypes = propTypes;

export default connect(FieldError);
