import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { Helmet } from 'react-helmet';
import amplitude from 'amplitude-js';

import AdminLayout from './layouts/AdminLayout';
import AuthLayout from './layouts/AuthLayout';

import BubbleFrame from './pages/BubbleFrame';
import Integrate from './pages/Integrate';
import Settings from './pages/Settings';
import ResetPassword from './pages/ResetPassword';
import SetPassword from './pages/SetPassword';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Test from './pages/Test';
import Upgrade from './pages/Upgrade';

import apolloClient from './apollo';


const CustomRoute = (props) => {
  const {
    path,
    component: Component,
    componentProps,
    layout: Layout = ({ children }) => {
      return children;
    },
    needsToBeLoggedIn,
    needsToBeLoggedOut,
    redirectTo,
    title,
    ...rest
  } = props;

  return (
    <Route
      {...rest}
      render={(matchProps) => {
        const { history } = matchProps;

        const userId = window.localStorage.getItem('Meteor.userId');

        if (redirectTo) {
          return history.push(redirectTo);
        }

        if (needsToBeLoggedIn && !userId) {
          history.push('/sign-up');
        }

        if (needsToBeLoggedOut && userId) {
          history.push('/');
        }

        return (
          <Layout {...matchProps}>
            <Helmet>
              <title>
                {title ? `${title} - ContactBubble` : 'ContactBubble'}
              </title>
            </Helmet>

            <Component {...matchProps} {...(componentProps || {})} />
          </Layout>
        );
      }}
    />
  );
};

function App() {
  useEffect(() => {
    amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY, null, {
      saveEvents: true,
      includeUtm: true,
      includeReferrer: true,
      includeGclid: true,
      trackingOptions: {
        city: false,
        ip_address: false,
      },
    });
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <Router>
        <Switch>
          <CustomRoute
            exact
            path="/bubble"
            component={BubbleFrame}
            layout={undefined}
          />
          <CustomRoute
            exact
            path="/"
            redirectTo="/settings"
            component={BubbleFrame}
            layout={null}
          />

          <CustomRoute
            exact
            path="/settings"
            needsToBeLoggedIn
            component={Settings}
            layout={AdminLayout}
            title="Settings"
          />
          <CustomRoute
            exact
            path="/install"
            needsToBeLoggedIn
            component={Integrate}
            layout={AdminLayout}
            title="Install"
          />

          <CustomRoute
            exact
            path="/test/:profileId"
            needsToBeLoggedIn
            component={Test}
            layout={AdminLayout}
            title="Test"
          />
          <CustomRoute
            exact
            path="/upgrade"
            needsToBeLoggedIn
            component={Upgrade}
            layout={AdminLayout}
            title="Upgrade"
          />
          <CustomRoute
            exact
            path="/welcome"
            needsToBeLoggedIn
            component={Integrate}
            componentProps={{ isFirstVisit: true }}
            layout={AdminLayout}
            title="Welcome"
          />

          <CustomRoute
            exact
            path="/reset-password"
            needsToBeLoggedOut
            component={ResetPassword}
            layout={AuthLayout}
            title="Reset password"
          />
          <CustomRoute
            exact
            path="/set-password/:token"
            needsToBeLoggedOut
            component={SetPassword}
            layout={AuthLayout}
            title="Set password"
          />
          <CustomRoute
            exact
            path="/sign-in"
            needsToBeLoggedOut
            component={SignIn}
            layout={AuthLayout}
            title="Sign in"
          />
          <CustomRoute
            exact
            path="/sign-up"
            needsToBeLoggedOut
            component={SignUp}
            layout={AuthLayout}
            title="Sign up"
          />
        </Switch>
      </Router>
    </ApolloProvider>
  );
}

export default App;
