import React from "react";

import "react-tippy/dist/tippy.css";

import { ReactComponent as Logo } from "../assets/logo-vertical.svg";

const AuthLayout = props => {
  const { children } = props;

  return (
    <div className="bg-gray-100  w-full h-screen flex items-center overflow-hidden">
      <div className="flex-grow p-4">
        <Logo width="200" height="150" className="block mx-auto mb-4" />

        {children}
      </div>
    </div>
  );
};

export default AuthLayout;
