import React from "react";
import { Tooltip } from "react-tippy";
import { Link } from "react-router-dom";

import { LockIcon } from "../Icons";

const FeatureLock = () => {
  return (
    <Tooltip
      style={{
        display: "inline-block",
        lineHeight: "1"
      }}
      html={
        <div className="max-w-xs">
          Available with pro - click to learn more!
        </div>
      }
      arrow
      popperOptions={{
        modifiers: {
          preventOverflow: {
            enabled: false
          },
          hide: {
            enabled: false
          },
          flip: {
            enabled: true
          }
        }
      }}
    >
      <Link
        to="/upgrade"
        className="inline-block align-middle text-orange-500 p-1 hover:text-orange-600 hover:bg-offwhite rounded"
      >
        <LockIcon size={22} />
      </Link>
    </Tooltip>
  );
};

export default FeatureLock;
