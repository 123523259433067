export const validate = (values) => {
  const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isEmailValid = regEx.test(String(values.email).toLowerCase());

  return {
    ...(!values.name && { name: 'nameFieldError' }),
    ...(!isEmailValid && { email: 'emailFieldError' }),
    ...(!values.message && { message: 'messageFieldError' }),
  };
};
