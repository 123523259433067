import React from "react";
import PropTypes from "prop-types";
import { Field, Form, Formik } from "formik";
import { Link } from "react-router-dom";

import { loginWithPassword } from "meteor-apollo-accounts";

import apolloClient from "../apollo";
import { useStartRef } from "../helpers";

import {
  FieldError,
  FormResponse,
  validate
} from "../components/FormikHelpers";

const propTypes = {
  history: PropTypes.object.isRequired
};

const SignIn = props => {
  const { history } = props;

  const startRef = useStartRef(null);

  const signIn = async (values, formik) => {
    const { setStatus, setSubmitting } = formik;
    const { email, password } = values;

    try {
      await loginWithPassword({ email, password }, apolloClient);
      history.push("/");
    } catch (err) {
      setStatus({ err });
    }

    setSubmitting(false);
  };

  return (
    <div className="container-sm">
      <div className="callout p-8">
        <h1 className="h2 text-center">Sign in</h1>
        <Formik
          initialValues={{
            email: "",
            password: ""
          }}
          validate={values => {
            return validate(values, [
              { key: "email", label: "email", validation: "email" },
              "password"
            ]);
          }}
          onSubmit={signIn}
          validateOnBlur={false}
          render={formik => {
            const { isSubmitting, isValid } = formik;

            return (
              <Form>
                <label htmlFor="email">Email</label>
                <Field
                  innerRef={startRef}
                  type="email"
                  name="email"
                  placeholder="Your email address"
                  required
                />
                <FieldError field="email" />

                <label htmlFor="password">Password</label>
                <Field
                  type="password"
                  name="password"
                  placeholder="Your password"
                  required
                />
                <FieldError field="password" />

                <p className="text-right">
                  <Link to="/reset-password">Forgot your password?</Link>
                </p>

                <FormResponse />

                <button
                  className="button button-primary w-full"
                  type="submit"
                  disabled={isSubmitting || !isValid}
                >
                  Sign in
                </button>
              </Form>
            );
          }}
        />
      </div>
      <div className="text-center">
        <Link to="/sign-up">No account yet? Sign up instead</Link>
      </div>
    </div>
  );
};

SignIn.propTypes = propTypes;

export default SignIn;
