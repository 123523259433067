import ApolloClient from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onTokenChange } from 'meteor-apollo-accounts';

const apolloClient = new ApolloClient({
  uri: `${process.env.REACT_APP_SERVER_URL}/graphql`,
  request: (operation) => {
    operation.setContext(() => {
      return {
        headers: {
          authorization: localStorage.getItem('Meteor.loginToken'),
        },
      };
    });
  },
  cache: new InMemoryCache({
    dataIdFromObject: (obj) => {
      return obj._id || null;
    },
  }),
});

onTokenChange(function() {
  apolloClient.resetStore();
});

export default apolloClient;
