export const getNameParts = (name) => {
  if (!name) return ['', ''];

  const firstName = name
    .split(' ')
    .slice(0, 1)
    .join(' ') || '';
  const lastName = name
    .split(' ')
    .slice(1)
    .join(' ') || '';

  return [firstName, lastName];
};

export const createEvent = (name, props = {}) => {
  if (window.CustomEvent) {
    return new CustomEvent(name, props);
  }

  const event = document.createEvent('CustomEvent');
  event.initCustomEvent(name, true, true, props);

  return event;
};
