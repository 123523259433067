import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';

import FeatureLock from '../FeatureLock';

const propTypes = {
  formik: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  isLocked: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

const defaultProps = {
  isLocked: false,
};

const SwitchField = (props) => {
  const {
    formik, label, isLocked, name,
  } = props;
  const { setFieldValue, setFieldTouched, values } = formik;

  return (
    <div className="flex flex-padded items-center mb-4">
      <div className="flex-grow">
        <label htmlFor={name}>
          {label}
          {' '}
          {isLocked && <FeatureLock />}
        </label>
      </div>

      <div className="flex-shrink">
        <div className="switch pl-3">
          <input
            className="switch-input"
            id={name}
            type="checkbox"
            name={name}
            onChange={(ev) => {
              setFieldValue(name, ev.target.checked);
              setFieldTouched(name, true);
            }}
            checked={!!values[name]}
            disabled={isLocked}
          />
          <label className="switch-paddle" htmlFor={name} />
        </div>
      </div>
    </div>
  );
};

SwitchField.propTypes = propTypes;
SwitchField.defaultProps = defaultProps;

export default connect(SwitchField);
