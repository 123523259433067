import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';

const propTypes = {
  formik: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    setTouched: PropTypes.func.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
};

export const elementOptions = {
  hidePostalCode: true,
  style: {
    base: {
      iconColor: '#2E44CB',
      color: '#606f7b',
      fontWeight: 300,
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "San Francisco", "Helvetica Neue", Helvetica, Ubuntu, Roboto, Noto, "Segoe UI", Arial, sans-serif',
      fontSize: '1.25rem',

      '::placeholder': {
        color: '#b8c2cc',
      },
    },
    invalid: {
      iconColor: '#e3342f',
      color: '#e3342f',
    },
  },
};

const StripeElementsField = (props) => {
  const { formik, name } = props;
  const { setFieldValue, setTouched } = formik;

  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://js.stripe.com/v3/';
    document.body.appendChild(script);
    script.onload = () => {
      if (!window.Stripe) return;

      const stripe = window.Stripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
      );
      const elements = stripe.elements();

      const card = elements.create('card', elementOptions);
      card.mount('#card-element');

      card.on('change', (ev) => {
        setTouched(name, true);

        if (ev.complete) {
          setLoading(true);

          stripe.createToken(card).then((response) => {
            setError(null);

            if (response.error) {
              setError(response.error.message || response.error);
              setFieldValue(name, null);
            } else {
              setLoading(false);
              setFieldValue(name, response.token.id);
            }
          });
        }
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="input-field">
        <div id="card-element" />
      </div>

      {isLoading && (
        <div className="text-gray-400 text-sm mb-4 -mt-4">Loading...</div>
      )}

      {error && <div className="text-red-400 text-sm mb-4 -mt-4">{error}</div>}
    </>
  );
};

StripeElementsField.propTypes = propTypes;

export default connect(StripeElementsField);
