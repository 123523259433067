import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  alertColor: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  touched: PropTypes.bool.isRequired
};

const FieldError = props => {
  const { alertColor, error, touched } = props;

  if (!touched || !error) return false;

  return (
    <div
      className="text-sm mb-4 -mt-2"
      style={{
        color: alertColor
      }}
    >
      {error}
    </div>
  );
};

FieldError.propTypes = propTypes;

export default FieldError;
