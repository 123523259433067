import React from "react";
import PropTypes from "prop-types";
import { Field, Form, Formik } from "formik";
import { useMutation } from "@apollo/react-hooks";
import { omit } from "lodash";

import { SAVE_RESPONSE_SETTINGS } from "../../../../queries";

import FeatureLock from "../../../../components/FeatureLock";
import {
  EditorField,
  FieldError,
  FormResponse,
  SwitchField,
  validate
} from "../../../../components/FormikHelpers";

import { getEmailParts } from "./helpers";

const propTypes = {
  profile: PropTypes.object.isRequired
};

const ResponseSettingsForm = props => {
  const { profile } = props;

  const [customFromEmailAccount, customFromEmailDomain] = getEmailParts(
    profile.responseCopyCustomFromEmail
  );

  const [saveResponseSettings] = useMutation(SAVE_RESPONSE_SETTINGS);

  const handleSubmit = async (values, formik) => {
    const { resetForm, setStatus, setSubmitting } = formik;

    try {
      await saveResponseSettings({
        variables: {
          profileId: profile._id,
          data: omit(values, [
            "customFromEmailAccount",
            "customFromEmailDomain"
          ])
        }
      });
      resetForm();
      setStatus({ success: "Your settings are saved successfully!" });
    } catch (err) {
      setStatus({ err });
    }

    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        customFromEmailAccount,
        customFromEmailDomain,
        responseCopyEmailTitle: profile.responseCopyEmailTitle,
        responseCopyEmailMessage: profile.responseCopyEmailMessage,
        responseCopyCustomFromEmail: profile.responseCopyCustomFromEmail,
        responseCopyFromName: profile.responseCopyFromName,
        responseCopyHasCustomFromEmail: profile.responseCopyHasCustomFromEmail,
        shouldSendResponseCopyEmail: profile.shouldSendResponseCopyEmail
      }}
      validate={values => {
        if (!values.shouldSendResponseCopyEmail) return {};

        if (!values.responseCopyHasCustomFromEmail) {
          return validate(values, [
            "responseCopyFromName",
            "responseCopyEmailTitle",
            "responseCopyEmailMessage"
          ]);
        }

        return validate(values, [
          "responseCopyFromName",
          "responseCopyEmailTitle",
          "responseCopyEmailMessage",
          {
            key: "responseCopyCustomFromEmail",
            label: "from email",
            validation: "email"
          }
        ]);
      }}
      onSubmit={handleSubmit}
      render={formik => {
        const {
          isSubmitting,
          isValid,
          handleChange,
          setFieldValue,
          setFieldTouched,
          values
        } = formik;

        return (
          <Form>
            <SwitchField
              label="Send response copy to your user"
              name="shouldSendResponseCopyEmail"
              isLocked={profile.plan === "free"}
            />

            {values.shouldSendResponseCopyEmail && (
              <>
                <label htmlFor="responseCopyFromName">From name</label>
                <Field
                  name="responseCopyFromName"
                  type="text"
                  placeholder="e.g. Bluth Company"
                />
                <FieldError field="responseCopyFromName" />

                <SwitchField
                  label="Use Custom From Email"
                  name="responseCopyHasCustomFromEmail"
                  isLocked={profile.plan === "free"}
                />

                {values.responseCopyHasCustomFromEmail && (
                  <>
                    {profile.sendingDomains.length === 0 && (
                      <div className="callout warning">
                        Add a sending domain first
                      </div>
                    )}

                    {profile.sendingDomains.length > 0 && (
                      <>
                        <div className="flex items-center">
                          <div className="flex-1">
                            <Field
                              name="customFromEmailAccount"
                              type="text"
                              onChange={ev => {
                                handleChange(ev);

                                const isComplete =
                                  ev.target.value &&
                                  values.customFromEmailDomain;
                                setFieldValue(
                                  "responseCopyCustomFromEmail",
                                  isComplete
                                    ? `${ev.target.value}@${values.customFromEmailDomain}`
                                    : ""
                                );
                                setFieldTouched(
                                  "responseCopyCustomFromEmail",
                                  true
                                );
                              }}
                            />
                          </div>
                          <div className="flex-shrink mb-4 px-2">@</div>
                          <div className="flex-1">
                            <Field
                              name="customFromEmailDomain"
                              component="select"
                              onChange={ev => {
                                handleChange(ev);

                                const isComplete =
                                  ev.target.value &&
                                  values.customFromEmailAccount;
                                setFieldValue(
                                  "responseCopyCustomFromEmail",
                                  isComplete
                                    ? `${values.customFromEmailAccount}@${ev.target.value}`
                                    : ""
                                );
                                setFieldTouched(
                                  "responseCopyCustomFromEmail",
                                  true
                                );
                              }}
                            >
                              <option value="">Select custom domain</option>
                              {profile.sendingDomains.map(sendingDomain => {
                                return (
                                  <option
                                    key={sendingDomain.domain}
                                    value={sendingDomain.domain}
                                  >
                                    {sendingDomain.domain}
                                  </option>
                                );
                              })}
                            </Field>
                          </div>
                        </div>
                        <FieldError field="responseCopyCustomFromEmail" />
                      </>
                    )}
                  </>
                )}

                <label htmlFor="responseCopyEmailTitle">
                  Response copy email title{" "}
                  {profile.plan === "free" && <FeatureLock />}
                </label>
                <Field
                  type="text"
                  name="responseCopyEmailTitle"
                  placeholder="Your greeting to users"
                  disabled={profile.plan === "free"}
                />
                <FieldError field="responseCopyEmailTitle" />

                <label htmlFor="responseCopyEmailMessage">
                  Response copy email message{" "}
                  {profile.plan === "free" && <FeatureLock />}
                </label>
                <EditorField
                  name="responseCopyEmailMessage"
                  id="responseCopyEmailMessage"
                  rows="10"
                  disabled={profile.plan === "free"}
                />
                <FieldError field="responseCopyEmailMessage" />
              </>
            )}

            <FormResponse />

            <button
              className="button button-gray"
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              Save
            </button>
          </Form>
        );
      }}
    />
  );
};

ResponseSettingsForm.propTypes = propTypes;

export default ResponseSettingsForm;
