export default {
  attachmentLabel: 'Add attachment',
  formResponseButtonText: 'Send another message',
  formSubmitLoadingText: 'Loading...',
  formSubmitText: 'Send Message',
  nameFieldError: 'A name is required',
  nameFieldPlaceholder: 'Name',
  emailFieldError: 'A valid email address is required',
  emailFieldPlaceholder: 'Email',
  messageFieldError: 'A message is required',
  messageFieldPlaceholder: 'Message',
};
