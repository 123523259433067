import React, { useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import amplitude from 'amplitude-js';

import 'react-tippy/dist/tippy.css';

import { ReactComponent as Logo } from '../assets/logo-horizontal.svg';

import { isAdmin } from '../helpers';
import { GET_ME } from '../queries';

const AdminLayout = (props) => {
  const { children, location } = props;

  const mainEl = useRef(null);

  useEffect(() => {
    if (!mainEl.current) return;

    mainEl.current.scrollTop = 0;
  }, [location.key]);

  const { loading, data } = useQuery(GET_ME);
  const profile = !loading && data && data.me;

  useEffect(() => {
    if (!profile) return;

    amplitude.getInstance().setUserId(profile.ownerId);

    const identify = new amplitude.Identify()
      .set('companyName', profile.companyName)
      .set('email', profile.email)
      .set('fullName', profile.fullName)
      .set('language', profile.language)
      .set('plan', profile.plan)
      .set('transmissionCount', profile.transmissionCount);

    amplitude.getInstance().identify(identify);
  }, [profile]);

  if (!profile) return false;

  return (
    <div className="bg-gray-100 w-full h-screen flex flex-col overflow-hidden">
      <header className="container-md flex-shrink z-50">
        <div className=" bg-white rounded-b-lg shadow-xl inline-block mx-auto flex items-center p-2 justify-between">
          <div className="flex-shrink">
            <Link to="/">
              <div className="px-2">
                <Logo width="225" height="50" />
              </div>
            </Link>
          </div>

          <div className="flex-shrink">
            <ul className="flex items-center list-reset">
              <li className="px-2">
                <NavLink to="/settings" activeClassName="font-bold">
                  Settings
                </NavLink>
              </li>
              <li className="px-2">
                <NavLink to="/install" activeClassName="font-bold">
                  Install
                </NavLink>
              </li>
              {profile.plan === 'free' && (
                <li className="px-2">
                  <NavLink to="/upgrade" className="button button-primary">
                    Go PRO
                  </NavLink>
                </li>
              )}
              {isAdmin(profile.email) && (
                <li className="px-2">
                  <NavLink
                    to={`/test/${profile._id}`}
                    activeClassName="font-bold"
                  >
                    Test
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </div>
      </header>
      <div className="flex-grow p-4 overflow-scroll" ref={mainEl}>
        {children}
      </div>
    </div>
  );
};

export default AdminLayout;
