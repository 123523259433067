import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';

import { FieldError, validate } from '../../..';
import Modal from '../../../../Modal';

const LinkNode = (props) => {
  const {
    children, editor, node, attributes,
  } = props;

  const [isActive, setActive] = useState(false);

  const handleSubmit = (values, formik) => {
    const { href } = values;
    const { setSubmitting } = formik;

    editor.setInlines({
      type: 'link',
      data: {
        href,
      },
    });

    setSubmitting(false);
    setActive(false);
  };

  return (
    <>
      <Modal isActive={isActive} className="w-xs">
        <Formik
          initialValues={{
            href: node.data.get('href'),
          }}
          validate={(values) => {
            return validate(values, [
              {
                key: 'href',
                label: 'link',
                validation: 'url',
              },
            ]);
          }}
          onSubmit={handleSubmit}
          render={(formik) => {
            const { isValid } = formik;

            return (
              <Form
                onSubmit={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();

                  formik.handleSubmit(ev);
                }}
              >
                <label htmlFor="href">Link</label>
                <Field type="text" name="href" required autoComplete="off" />
                <FieldError field="href" />

                <button
                  type="button"
                  className="text-link text-sm"
                  onClick={() => {
                    editor.unwrapInline('link');
                    setActive(false);
                  }}
                >
                  Delete link instead
                </button>

                <div className="text-right">
                  <button
                    type="button"
                    className="button text-grey"
                    onClick={() => {
                      setActive(false);
                    }}
                  >
                    Cancel
                  </button>

                  <button className="button button-gray" type="submit" disabled={!isValid}>
                    Ok
                  </button>
                </div>
              </Form>
            );
          }}
        />
      </Modal>

      <button
        {...attributes}
        type="button"
        onClick={() => {
          setActive(true);
        }}
        className="text-link"
      >
        {children}
      </button>
    </>
  );
};

export default LinkNode;
