import React from "react";
import PropTypes from "prop-types";
import readableColor from "polished/lib/color/readableColor";

const propTypes = {
  alertColor: PropTypes.string.isRequired,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  status: PropTypes.object
};

const defaultProps = {
  status: undefined
};

const FormAlert = props => {
  const { alertColor, errorMessage, status } = props;

  if (!status) return false;

  return (
    <div
      className="block p-4 mb-4 rounded"
      style={{
        backgroundColor: alertColor,
        color: readableColor(alertColor, "#2d3748", "#f7fafc")
      }}
    >
      {errorMessage}

      <div style={{ marginTop: errorMessage ? "1rem" : "0rem" }}>
        (
        {status.error.error ||
          status.error.message ||
          status.error.reason ||
          status.error}
        )
      </div>
    </div>
  );
};

FormAlert.propTypes = propTypes;
FormAlert.defaultProps = defaultProps;

export default FormAlert;
