import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { uniq } from 'lodash';
import { useMutation } from '@apollo/react-hooks';

import { SAVE_MESSENGER_SETTINGS } from '../../../../queries';

import {
  ArrayField,
  ColorField,
  EditorField,
  FieldError,
  FormResponse,
  SwitchField,
  validate,
} from '../../../../components/FormikHelpers';
import Help from '../../../../components/Help';

const propTypes = {
  profile: PropTypes.object.isRequired,
};

const MessengerSettingsForm = (props) => {
  const { profile } = props;

  const [saveMessengerSettings] = useMutation(SAVE_MESSENGER_SETTINGS);

  const handleSubmit = async (values, formik) => {
    const { resetForm, setStatus, setSubmitting } = formik;

    try {
      await saveMessengerSettings({
        variables: {
          profileId: profile._id,
          data: values,
        },
      });
      resetForm();
      setStatus({ success: 'Your settings are saved successfully!' });
    } catch (err) {
      setStatus({ err });
    }

    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        alertColor: profile.alertColor,
        embeddedDomains: profile.embeddedDomains,
        toEmails: profile.toEmails,
        greetingText: profile.greetingText,
        introText: profile.introText,
        formResponseText: profile.formResponseText,
        language: profile.language,
        primaryColor: profile.primaryColor,
        successColor: profile.successColor,
        shouldShowBranding: profile.shouldShowBranding,
        shouldShowCustomFormResponse: profile.shouldShowCustomFormResponse,
      }}
      validate={(values) => {
        const errors = validate(values, [
          'greetingText',
          'introText',
          'primaryColor',
          'language',
        ]);

        const getToEmailsError = (toEmails) => {
          if (!toEmails || !toEmails.length) {
            return {
              toEmails:
                'You need to send the emails to at least one email address',
            };
          }

          if (toEmails.length > 4) {
            return { toEmails: "You can't have that many email addresses" };
          }

          if (uniq(toEmails).length !== toEmails.length) {
            return {
              toEmails: 'Each to email should be unique',
            };
          }
        };

        const toEmailsError = getToEmailsError(values.toEmails);

        const toEmailsAsObject = values.toEmails.reduce(
          (obj, toEmail, index) => {
            return { ...obj, [`toEmails.${index}`]: toEmail };
          },
          {},
        );

        const toEmailErrors = validate(
          toEmailsAsObject,
          values.toEmails.map((toEmail, index) => {
            return {
              key: `toEmails.${index}`,
              label: 'email',
              validation: 'email',
            };
          }),
        );

        return { ...errors, ...toEmailsError, ...toEmailErrors };
      }}
      onSubmit={handleSubmit}
      render={(formik) => {
        const { isSubmitting, isValid, values } = formik;

        return (
          <Form>
            <label htmlFor="greetingText">Greeting Text</label>
            <Field
              type="text"
              name="greetingText"
              placeholder="Your greeting to users"
              required
            />
            <FieldError field="greetingText" />

            <label htmlFor="introText">Intro Text</label>
            <EditorField
              name="introText"
              placeholder="Your introduction text to users"
              required
            />
            <FieldError field="introText" />

            <SwitchField
              label="Show branding"
              name="shouldShowBranding"
              isLocked={profile.plan === 'free'}
            />

            <SwitchField
              label="Add custom form response"
              name="shouldShowCustomFormResponse"
              isLocked={profile.plan === 'free'}
            />

            {values.shouldShowCustomFormResponse && (
              <>
                <label htmlFor="formResponseText">Form response text</label>
                <EditorField
                  name="formResponseText"
                  placeholder="The response text your users see after they submit a form"
                  required
                />
                <FieldError field="formResponseText" />
                <hr />
              </>
            )}

            <label htmlFor="primaryColor">Primary color</label>
            <ColorField name="primaryColor" />

            <label htmlFor="successColor">Response success color</label>
            <ColorField name="successColor" />

            <label htmlFor="alertColor">Form alert color</label>
            <ColorField name="alertColor" />

            <label htmlFor="language">Language</label>
            <Field name="language" component="select" required>
              <option value="en">English</option>
              <option value="nl">Nederlands</option>
            </Field>

            <label htmlFor="toEmails">To Emails</label>
            <ArrayField
              name="toEmails"
              fieldRender={(option, name) => {
                return (
                  <>
                    <Field
                      name={name}
                      type="email"
                      placeholder="michaelbluth@thebananastand.com"
                    />
                    <FieldError field={name} />
                  </>
                );
              }}
              maxItems={5}
              minItems={1}
            />
            <FieldError field="toEmails" />

            <label htmlFor="embeddedDomains">
Allowed domains
              {' '}
              <Help>
Provide the domains (
                <em>include https:// and exclude pathnames</em>
) where you want to install the bubble on. Your first three embedded domains and
                {' '}
                <em>localhost</em>
                {' '}
are allowed automatically.
              </Help>
            </label>
            <ArrayField
              name="embeddedDomains"
              fieldRender={(option, name) => {
                return (
                  <>
                    <Field
                      name={name}
                      type="text"
                      placeholder="https://www.bananastand.com"
                    />
                    <FieldError field={name} />
                  </>
                );
              }}
              maxItems={3}
            />
            <FieldError field="embeddedDomains" />


            <FormResponse />

            <button
              className="button button-gray"
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              Save
            </button>
          </Form>
        );
      }}
    />
  );
};

MessengerSettingsForm.propTypes = propTypes;

export default MessengerSettingsForm;
