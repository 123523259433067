import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  size: PropTypes.number,
};

const defaultProps = {
  size: 24,
};

const Quote = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="align-middle"
    >
      <path d="M2.04608 11.1294C2.335 13.063 3.97966 14.4409 5.84655 14.5298C5.37983 16.2634 4.24635 16.9524 2.86841 17.3524C2.73506 17.3969 2.75728 17.5302 2.75728 17.5302L2.97953 18.9082C2.97953 18.9082 3.00176 19.0193 3.17956 18.9971C7.9357 18.4637 11.1583 14.841 10.5582 10.4404C10.0026 7.39561 7.62455 6.21769 5.42428 6.52884C3.22401 6.88444 1.71271 8.92914 2.04608 11.1294ZM17.2257 14.5298C16.7812 16.2634 15.6033 16.9524 14.2476 17.3524C14.1142 17.3969 14.1365 17.5302 14.1365 17.5302L14.3365 18.9082C14.3365 18.9082 14.3587 19.0193 14.5365 18.9971C19.2927 18.4859 22.4931 14.8632 21.9374 10.4627C21.3596 7.39561 18.9593 6.21769 16.7812 6.52884C14.581 6.88444 13.0697 8.92914 13.4253 11.1294C13.7142 13.0852 15.3366 14.4632 17.2257 14.5298Z" />
    </svg>
  );
};

Quote.propTypes = propTypes;
Quote.defaultProps = defaultProps;

export default Quote;
