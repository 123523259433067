import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { render } from 'mustache';
import { useMutation, useQuery } from '@apollo/react-hooks';
import readableColor from 'polished/lib/color/readableColor';
import classNames from 'classnames';
import useMobileDetect from 'use-mobile-detect-hook';

import { GET_PROFILE, SEND_MESSAGE } from '../../queries';

import { MailIcon, ZapIcon } from '../Icons';

import Form from './components/Form';

import { createEvent, getNameParts } from './helpers';

const TRANSITION_DURATION = 320;

const propTypes = {
  customFields: PropTypes.object,
  domain: PropTypes.string,
  email: PropTypes.string,
  isTesting: PropTypes.bool,
  name: PropTypes.string,
  profileId: PropTypes.string.isRequired,
};

const defaultProps = {
  customFields: {},
  domain: 'http://localhost:4050',
  email: '',
  isTesting: false,
  name: '',
};

const Bubble = (props) => {
  const {
    customFields, domain, email, isTesting, name, profileId,
  } = props;

  const detectMobile = useMobileDetect();

  const { loading, data } = useQuery(GET_PROFILE, {
    variables: { domain, profileId },
  });

  const profile = !loading && data && data.profile;

  const [sendMessage] = useMutation(SEND_MESSAGE);

  const emailField = useRef(null);
  const messageField = useRef(null);
  const nameField = useRef(null);
  const contactbubbleWrapper = useRef(null);

  const [isOpen, setOpened] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState(undefined);

  useEffect(() => {
    const style = document.createElement('style');

    style.innerHTML = `
     .contactbubble a {
       color: ${profile.primaryColor};
     }
    `;

    document.head.appendChild(style);
  }, [profile.primaryColor]);

  useEffect(() => {
    if (!isOpen) return;

    const windowWidth = window.innerWidth || 0;

    if (!isOpen || windowWidth < 640) return;

    if (!name && nameField.current && typeof nameField.current.focus === 'function') {
      nameField.current.focus();
      return;
    }
    if (!email && emailField.current && typeof emailField.current.focus === 'function') {
      emailField.current.focus();
      return;
    }

    if (messageField.current && typeof messageField.current.focus === 'function') {
      messageField.current.focus();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const [firstName, lastName] = getNameParts(name);

  const model = {
    email,
    firstName,
    lastName,
    name,
    ...customFields,
  };

  const handleToggleLauncher = (newIsOpen) => {
    if (window.parent) {
      window.setTimeout(
        () => {
          window.parent.postMessage({ isOpen: newIsOpen }, '*');
        },
        newIsOpen ? 0 : TRANSITION_DURATION,
      );
    }

    window.setTimeout(
      () => {
        setOpened(!isOpen);
      },
      newIsOpen ? TRANSITION_DURATION : 0,
    );
  };

  const handleClickOutside = (ev) => {
    if (!isOpen) return;

    const isChild = contactbubbleWrapper.current.contains(ev.target);

    if (!isChild) {
      ev.stopPropagation();
      handleToggleLauncher(!isOpen);
    }
  };

  const receiveEvent = (ev) => {
    const { data } = ev;

    if (!data || typeof data !== 'object') return;

    if (data.type === 'open') {
      handleToggleLauncher(true);
    }
  };

  useEffect(() => {
    window.addEventListener('message', receiveEvent);

    return () => {
      return window.removeEventListener('message', receiveEvent);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactbubbleWrapper.current]);

  if (!profile) return false;

  const { greetingText, introText, toEmails } = profile;

  const greetingTextMustached = render(greetingText, model);
  const introTextMustached = render(introText, model);

  const handleReset = () => {
    setIsSubmitted(false);
    setIsSubmitting(false);
    setStatus(undefined);
  };

  const handleSendMessage = async (values, callback) => {
    setIsSubmitting(true);

    const sendMessageData = {
      customFields,
      isTesting,
      messageFields: values,
    };

    try {
      await sendMessage({
        variables: {
          profileId,
          data: sendMessageData,
        },
      });

      const event = createEvent('contactbubble.sentMessage', {
        detail: sendMessageData,
      });
      window.dispatchEvent(event);

      setIsSubmitted(true);

      callback(undefined);
    } catch (err) {
      setStatus({ error: err.message || err });
      callback(err);
    }

    setIsSubmitting(false);
  };

  const toEmail = toEmails.length ? toEmails[0] : undefined;

  return (
    <div
      ref={contactbubbleWrapper}
      className={classNames(
        'contactbubble w-full md:w-128 fixed bottom-0 right-0 pr-4 pr-4 mb-4 mr-4',
        {
          'pointer-events-none': !isOpen,
          'pointer-events-auto': isOpen,
        },
      )}
      style={{ zIndex: 9000 }}
    >
      <div
        className="w-full md:w-128 h-screen md:h-auto fixed top-0 left-0 md:relative rounded-lg bg-white shadow-xl p-4 md:p-8 mb-4 transition-in-out block overflow-auto"
        style={{
          ...(detectMobile.isDesktop() && { maxHeight: 'calc(100vh - 120px)' }),
          transition: `opacity ${TRANSITION_DURATION}ms ease-out, transform ${Math.round(
            TRANSITION_DURATION / 1.5,
          )}ms ease-out`,
          ...(isOpen && {
            opacity: 1,
            transform: 'none',
          }),
          ...(!isOpen && {
            opacity: 0,
            transform: 'translate3D(10px, 25px, 0)',
          }),
        }}
      >
        <button
          className="appearance-none border-none text-3xl text-gray-320 absolute top-0 right-0 py-4 px-8 block md:hidden"
          type="button"
          onClick={() => {
            handleToggleLauncher(!isOpen);
          }}
        >
          ×
        </button>

        <div>
          {!isSubmitted && (
            <div>
              <h1 className="text-3xl md:text-4xl font-bold p-0 m-0 pt-8 md:pt-0">
                {greetingTextMustached}
              </h1>
              <div
                className="text-gray-600 text-base md:text-lg no-margin-last-child"
                dangerouslySetInnerHTML={{ __html: introTextMustached }}
              />
            </div>
          )}

          <Form
            model={model}
            profile={profile}
            onSubmit={handleSendMessage}
            name={name}
            email={email}
            nameRef={nameField}
            emailRef={emailField}
            messageRef={messageField}
            onReset={handleReset}
            errorMessage={(
              <span>
                Oops! Something went wrong. Please send an email to
                {' '}
                <a className="text-white" href={`mailto:${toEmail}`}>
                  {toEmail}
                </a>
                {' '}
                instead.
              </span>
)}
            isSubmitted={isSubmitted}
            isSubmitting={isSubmitting}
            isOpen={isOpen}
            status={status}
          />
        </div>

        {profile.shouldShowBranding && (
          <div className="text-center text-gray-500 text-sm">
            <span className="text-yellow-500">
              <ZapIcon size={16} />
            </span>
            {' '}
            by
            {' '}
            <a
              className="text-primary-500 underline hover:text-primary-700"
              href="https://www.contactbubble.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              ContactBubble
            </a>
            {' '}
            and
            {' '}
            <MailIcon size={16} />
          </div>
        )}
      </div>
      <div className="text-right w-16 h-16">
        <button
          type="button"
          onClick={() => {
            handleToggleLauncher(!isOpen);
          }}
          className="appearance-none border-none outline-none absolute right-0 w-16 h-16 leading-none rounded-full py-2 px-2 text-white shadow-lg cursor-pointer pointer-events-auto"
          style={{
            backgroundColor: profile.primaryColor,
            color: readableColor(profile.primaryColor, '#2d3748', '#f7fafc'),
          }}
        >
          <MailIcon size={40} />
        </button>
      </div>
    </div>
  );
};

Bubble.propTypes = propTypes;
Bubble.defaultProps = defaultProps;

export default Bubble;
