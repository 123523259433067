import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Field, Form, Formik } from "formik";
import { pick } from "lodash";
import { useMutation } from "@apollo/react-hooks";

import {
  ADD_SENDING_DOMAIN,
  REMOVE_SENDING_DOMAIN,
  VERIFY_SENDING_DOMAIN
} from "../../../../queries";

import { FormResponse, validate } from "../../../../components/FormikHelpers";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  TrashIcon
} from "../../../../components/Icons";

const propTypes = {
  profile: PropTypes.object.isRequired
};

const SendingDomainsForm = props => {
  const { profile } = props;

  const [expandedIndex, setExpandedIndex] = useState(undefined);

  const [addSendingDomain] = useMutation(ADD_SENDING_DOMAIN);
  const [removeSendingDomain] = useMutation(REMOVE_SENDING_DOMAIN);
  const [verifySendingDomain] = useMutation(VERIFY_SENDING_DOMAIN);

  const handleAddSendingDomain = async (values, formik) => {
    const { resetForm, setStatus, setSubmitting } = formik;

    try {
      await addSendingDomain({
        variables: {
          profileId: profile._id,
          domain: values.sendingDomain
        }
      });
      resetForm();
      setStatus({ success: "Your sending domain is created!" });
    } catch (err) {
      setStatus({ err });
    }

    setSubmitting(false);
  };

  const handleRemoveSendingDomain = async domain => {
    await removeSendingDomain({
      variables: {
        profileId: profile._id,
        domain
      }
    });
  };

  const handleVerifySendingDomain = async (domain, formik) => {
    const { setStatus } = formik;

    setStatus(undefined);

    try {
      await verifySendingDomain({
        variables: {
          profileId: profile._id,
          domain
        }
      });

      setStatus({ success: "Your domain has been verified!" });
    } catch (err) {
      setStatus({ err });
    }
  };

  return (
    <Formik
      initialValues={pick(profile, ["_id", "sendingDomains"])}
      onSubmit={handleAddSendingDomain}
      validate={values => {
        return validate(values, [
          {
            key: "sendingDomain",
            label: "sending domain",
            validation: "domain"
          }
        ]);
      }}
      render={formik => {
        const { isSubmitting, isValid } = formik;

        return (
          <Form>
            <label htmlFor="sendingDomains">Sending domains</label>

            {(profile.sendingDomains || []).map((sendingDomain, index) => {
              const { dkim } = sendingDomain;
              const isExpanded = expandedIndex === index;

              return (
                <Fragment key={`${sendingDomain.domain}-${index}`}>
                  <div className="flex flex-padded items-center mb-4">
                    <div className="flex-grow">{sendingDomain.domain}</div>
                    <div className="flex-shrink">
                      {!isExpanded && (
                        <button
                          className="subtle-link"
                          onClick={() => {
                            setExpandedIndex(index);
                          }}
                        >
                          <ChevronDownIcon />{" "}
                        </button>
                      )}

                      {isExpanded && (
                        <button
                          className="subtle-link"
                          onClick={() => {
                            setExpandedIndex(undefined);
                          }}
                        >
                          <ChevronUpIcon />{" "}
                        </button>
                      )}
                    </div>
                    <div className="flex-shrink">
                      <button
                        className="subtle-link"
                        type="button"
                        onClick={() => {
                          handleRemoveSendingDomain(sendingDomain.domain);
                        }}
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  </div>
                  {isExpanded && (
                    <div className="border p-4 rounded mb-4">
                      <div className="flex mb-4">
                        <div className="font-bold flex-grow">
                          DNS Settings (status:{" "}
                          {sendingDomain.status || "unverified"})
                        </div>

                        <button
                          className="button button-primary flex-shrink"
                          type="button"
                          onClick={() => {
                            handleVerifySendingDomain(
                              sendingDomain.domain,
                              formik
                            );
                          }}
                        >
                          Verify
                        </button>
                      </div>

                      <div className="bg-offwhite p-4 border text-grey-dark">
                        <div className="flex flex-padded">
                          <div className="w-24 flex-no-shrink">Type</div>
                          <div className="flex-grow">
                            <p>TXT</p>
                          </div>
                        </div>
                        <div className="flex flex-padded">
                          <div className="w-24 flex-no-shrink">Hostname</div>
                          <div className="flex-grow">
                            <p>
                              {dkim.selector}
                              ._domainkey.
                              {dkim.signing_domain}
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-padded">
                          <div className="w-24 flex-no-shrink">Value</div>
                          <div className="flex-grow">
                            <p className="break-all">
                              v=DKIM1; k=rsa; h=sha256; p=
                              {dkim.public}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Fragment>
              );
            })}

            <FormResponse />

            <div className="flex flex-padded items-center mb-4">
              <div className="flex-grow">
                <Field
                  name="sendingDomain"
                  type="text"
                  placeholder="thebananastand.com"
                  className="mb-0"
                  disabled={profile.plan === "free"}
                />
              </div>

              <div className="flex-shrink">
                <button
                  className="button button-gray"
                  type="submit"
                  disabled={!isValid || isSubmitting || profile.plan === "free"}
                >
                  Add domain
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

SendingDomainsForm.propTypes = propTypes;

export default SendingDomainsForm;
