import React, { useState } from "react";
import PropTypes from "prop-types";
import Textarea from "react-textarea-autosize";
import classNames from "classnames";

import { deselectOnBlur, selectOnFocus } from "./helpers";

const propTypes = {
  isSecondary: PropTypes.bool
};

const defaultProps = {
  isSecondary: false
};

const EmbedCode = props => {
  const { code } = props;

  const [success, setSuccess] = useState(false);

  const handleCopy = () => {
    const el = document.createElement("textarea");
    el.value = code;
    document.body.appendChild(el);
    el.select();
    el.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(el);

    setSuccess(true);

    window.setTimeout(() => {
      setSuccess(false);
    }, 2000);
  };

  return (
    <div className="mb-4">
      <Textarea
        className="font-mono"
        id="embedCode"
        readOnly
        onBlur={deselectOnBlur}
        onFocus={selectOnFocus}
        style={{
          cursor: "pointer"
        }}
        defaultValue={code}
      />

      <div className="flex flex-padded items-center">
        <div className="flex-shrink">
          <button
            type="button"
            className="button button-gray"
            onClick={handleCopy}
          >
            Copy
          </button>
        </div>
        <div
          className={classNames("text-gray-400", "transition-out", {
            "opacity-1": success,
            "opacity-0": !success
          })}
        >
          Copied
        </div>
      </div>
    </div>
  );
};

EmbedCode.propTypes = propTypes;
EmbedCode.defaultProps = defaultProps;

export default EmbedCode;
