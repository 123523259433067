import { lowerCase } from 'lodash';
import SimpleSchema from 'simpl-schema';

const validateItem = (values, item) => {
  if (typeof item === 'string') return !!values[item];
  if (!item.validation) return !!values[item.key];

  const testString = String(values[item.key]).toLowerCase();

  // TODO: Get rid of simpl-schema
  if (item.validation === 'domain') {
    return SimpleSchema.RegEx.Domain.test(testString);
  }

  if (item.validation === 'email') {
    return SimpleSchema.RegEx.EmailWithTLD.test(testString);
  }

  if (item.validation === 'url') {
    return SimpleSchema.RegEx.Url.test(testString);
  }
};

export const validate = (values, items) => {
  return items.reduce((errorsReducer, item) => {
    const itemKey = typeof item === 'string' ? item : item.key;

    const isValid = validateItem(values, item);

    if (!isValid) {
      const itemLabel = typeof item === 'string' ? lowerCase(item) : item.label;

      return { ...errorsReducer, [itemKey]: `A valid ${itemLabel} is required` };
    }

    return errorsReducer;
  }, {});
};

export const readDataUrl = (file, callback) => {
  const reader = new window.FileReader();

  reader.onloadend = function() {
    callback(reader.result);
  };

  reader.readAsDataURL(file);
};

export const getBase64File = (file) => {
  return new Promise((resolve) => {
    readDataUrl(file, (res) => {
      const { name, size, type } = file;

      resolve(
        {

          base64: res.split(',')[1],
          name,
          size,
          type,
        },
      );
    });
  });
};
