import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  size: PropTypes.number
};

const defaultProps = {
  size: 24
};

const Paperclip = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="align-middle"
    >
      <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
    </svg>
  );
};

Paperclip.propTypes = propTypes;
Paperclip.defaultProps = defaultProps;

export default Paperclip;
