import React, { useEffect } from 'react';

const Test = (props) => {
  const { match } = props;
  const { params } = match;
  const { profileId } = params;

  useEffect(() => {
    window.contactbubbleSettings = {
      app_id: profileId,
      name: 'Michael Bluth', // Sets your user's name
      email: 'joris.ruiter@gmail.com', // Sets your user's email
      customFields: {
        plan: 'pro', // Sets your user's plan property
      },
    };

    window.setTimeout(() => {
      window.contactbubbleSettings = {
        ...window.contactbubbleSettings,
        customFields: {
          ...window.contactbubbleSettings.customFields,
          plan: 'premium',
        },
      };
    }, 5000);

    const script = document.createElement('script');
    script.src = `${process.env.REACT_APP_CLIENT_URL}/embed.js`;
    document.head.appendChild(script);
  }, [profileId]);

  return (
    <div className="h-full w-full flex items-center">
      <div className="container-md text-center">
        <h1 className="text-gray-500">Test page</h1>
      </div>
    </div>
  );
};

export default Test;
