import React from "react";
import PropTypes from "prop-types";
import { connect } from "formik";
import { remove } from "lodash";

import { PlusIcon, TrashIcon } from "../Icons";

const propTypes = {
  fieldRender: PropTypes.func.isRequired,
  maxItems: PropTypes.number,
  minItems: PropTypes.number,
  name: PropTypes.string.isRequired
};

const defaultProps = {
  maxItems: 9999,
  minItems: 0
};

const ArrayField = props => {
  const { fieldRender, formik, maxItems, minItems, name } = props;
  const { setFieldValue, values } = formik;

  const items = values[name] || [];

  const handleAddItem = ev => {
    ev.preventDefault();

    const newValue = [...items, ...[""]];

    setFieldValue(name, newValue);
  };

  const handleRemoveItem = index => {
    const newValue = remove(items, (item, itemIndex) => {
      return index !== itemIndex;
    });

    setFieldValue(name, newValue);
  };

  return (
    <div className="mb-4">
      {items.map((item, index) => {
        return (
          <div
            key={`${name}-${index}`}
            className="flex flex-padded items-center"
          >
            <div className="flex-grow">
              {fieldRender(item, `${name}.${index}`, index)}
            </div>
            {items.length > minItems && (
              <div className="flex-shrink">
                <button
                  className="subtle-link mb-4"
                  onClick={() => {
                    handleRemoveItem(index);
                  }}
                >
                  <TrashIcon />
                </button>
              </div>
            )}
          </div>
        );
      })}

      {items.length < maxItems && (
        <button className="subtle-link" onClick={handleAddItem}>
          <PlusIcon /> Add item
        </button>
      )}
    </div>
  );
};

ArrayField.propTypes = propTypes;
ArrayField.defaultProps = defaultProps;

export default connect(ArrayField);
