import React from "react";
import { useQuery } from "@apollo/react-hooks";

import { GET_ME } from "../../queries";

import { CheckIcon } from "../../components/Icons";

import CheckoutForm from "./components/CheckoutForm";

const Upgrade = () => {
  const { loading, data } = useQuery(GET_ME);
  const profile = !loading && data && data.me;

  if (!profile) return false;

  return (
    <div className="container-md">
      <div className="callout">
        <h2 className="h3">
          Go Pro for only <span className="underline">US$ 9,-</span> per month.
        </h2>
        <p className="text-lg">This is what you get:</p>
        <div className="bg-offwhite callout">
          <ul className="list-reset text-lg text-gray-600">
            <li className="pb-2">
              <span className="text-green-400">
                <CheckIcon />
              </span>{" "}
              Hide branding
            </li>
            <li className="pb-2">
              <span className="text-green-400">
                <CheckIcon />
              </span>{" "}
              Customize thank you text
            </li>
            <li className="pb-2">
              <span className="text-green-400">
                <CheckIcon />
              </span>{" "}
              Unlimited emails
            </li>
            <li className="pb-2">
              <span className="text-green-400">
                <CheckIcon />
              </span>{" "}
              Customize or turn off response message
            </li>
            <li className="pb-2">
              <span className="text-green-400">
                <CheckIcon />
              </span>{" "}
              Use HTML templates to make your emails come to life
            </li>
            <li className="pb-2">
              <span className="text-green-400">
                <CheckIcon />
              </span>{" "}
              Add custom sending domains
            </li>
          </ul>
        </div>
        <CheckoutForm profile={profile} />
      </div>
    </div>
  );
};

export default Upgrade;
