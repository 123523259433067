import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  size: PropTypes.number
};

const defaultProps = {
  size: 24
};

const Send = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="align-middle"
    >
      <line x1="22" y1="2" x2="11" y2="13"></line>
      <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
    </svg>
  );
};

Send.propTypes = propTypes;
Send.defaultProps = defaultProps;

export default Send;
