import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Field, Form, Formik } from "formik";

import { FieldError, validate } from "../../../../components/FormikHelpers";

const propTypes = {
  onSubmit: PropTypes.func.isRequired
};

const StepOne = props => {
  const { onSubmit } = props;

  const startRef = useRef(null);

  useEffect(() => {
    startRef.current.focus();
  }, []);

  return (
    <Formik
      initialValues={{
        companyName: "",
        fullName: ""
      }}
      validate={values => {
        return validate(values, ["companyName", "fullName"]);
      }}
      onSubmit={onSubmit}
      validateOnBlur={false}
      render={formik => {
        const { isSubmitting, isValid } = formik;

        return (
          <Form>
            <h2 className="h3 text-primary-500 leading-tight">
              First, tell us a bit about yourself <br /> and your company.
            </h2>
            <label htmlFor="companyName">Company name</label>
            <Field
              innerRef={startRef}
              type="text"
              name="companyName"
              placeholder="Your company name"
              required
            />
            <FieldError field="companyName" />

            <label htmlFor="fullName">Name</label>
            <Field
              type="text"
              name="fullName"
              placeholder="Your name"
              required
            />
            <FieldError field="fullName" />

            <button
              className="button button-primary"
              disabled={!isValid || isSubmitting}
              type="submit"
            >
              Next →
            </button>
          </Form>
        );
      }}
    />
  );
};

StepOne.propTypes = propTypes;

export default StepOne;
