import gql from 'graphql-tag';

const ProfileFragment = gql`
  fragment ProfileFields on Profile {
    _id
    addressLine1
    addressLine2
    alertColor
    city
    companyName
    country
    email
    embeddedDomains
    firstName
    formResponseText
    fullName
    language
    greetingText
    introText
    ownerId
    plan
    primaryColor
    responseCopyEmailTitle
    responseCopyEmailMessage
    responseCopyCustomFromEmail
    responseCopyFromName
    responseCopyHasCustomFromEmail
    sendingDomains {
      domain
      dkim {
        public
        selector
        signing_domain
      }
      status
    }
    shouldSendResponseCopyEmail
    shouldShowBranding
    shouldShowCustomFormResponse
    state
    successColor
    toEmails
    vatNumber
    zipCode
  }
`;

export const GET_ME = gql`
  query getMe {
    me {
      ...ProfileFields
    }
  }
  ${ProfileFragment}
`;

export const GET_PROFILE = gql`
  query getProfile($domain: String!, $profileId: ID!) {
    profile(domain: $domain, profileId: $profileId) {
      ...ProfileFields
    }
  }
  ${ProfileFragment}
`;

export const SAVE_MESSENGER_SETTINGS = gql`
  mutation saveMessengerSettings(
    $profileId: ID!
    $data: MessengerSettingsInput!
  ) {
    saveMessengerSettings(profileId: $profileId, data: $data) {
      ...ProfileFields
    }
  }
  ${ProfileFragment}
`;

export const SAVE_PROFILE_SETTINGS = gql`
  mutation saveProfileSettings($profileId: ID!, $data: ProfileSettingsInput!) {
    saveProfileSettings(profileId: $profileId, data: $data) {
      ...ProfileFields
    }
  }
  ${ProfileFragment}
`;

export const SAVE_RESPONSE_SETTINGS = gql`
  mutation saveResponseSettings(
    $profileId: ID!
    $data: ResponseSettingsInput!
  ) {
    saveResponseSettings(profileId: $profileId, data: $data) {
      ...ProfileFields
    }
  }
  ${ProfileFragment}
`;

export const SEND_MESSAGE = gql`
  mutation sendMessage($profileId: ID!, $data: SendMessageInput!) {
    sendMessage(profileId: $profileId, data: $data) {
      status
    }
  }
`;

export const ADD_SENDING_DOMAIN = gql`
  mutation addSendingDomain($profileId: ID!, $domain: String!) {
    addSendingDomain(profileId: $profileId, domain: $domain) {
      ...ProfileFields
    }
  }
  ${ProfileFragment}
`;

export const REMOVE_SENDING_DOMAIN = gql`
  mutation removeSendingDomain($profileId: ID!, $domain: String!) {
    removeSendingDomain(profileId: $profileId, domain: $domain) {
      ...ProfileFields
    }
  }
  ${ProfileFragment}
`;

export const VERIFY_SENDING_DOMAIN = gql`
  mutation verifySendingDomain($profileId: ID!, $domain: String!) {
    verifySendingDomain(profileId: $profileId, domain: $domain) {
      ...ProfileFields
    }
  }
  ${ProfileFragment}
`;

export const UPGRADE_ACCOUNT = gql`
  mutation upgradeAccount(
    $profileId: ID!
    $data: UpgradeAccountInput!
    $stripeToken: String!
  ) {
    upgradeAccount(
      profileId: $profileId
      data: $data
      stripeToken: $stripeToken
    ) {
      ...ProfileFields
    }
  }
  ${ProfileFragment}
`;
