import React from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

const Modal = (props) => {
  const { children, className, isActive } = props;

  const container = document.getElementById('modal');


  if (!container) return false;

  const modalWrapperClassName = classNames(
    'flex',
    'items-center',
    'justify-center',
    'fixed',
    'top-0',
    'left-0',
    'w-screen',
    'h-screen',
    'bg-darker',
    'z-80',
    'transition-out',
    {
      'opacity-100 pointer-events-auto': isActive,
      'opacity-0 pointer-events-none': !isActive,
    },
  );

  const modalClassname = classNames(
    className,
    'bg-white',
    'border',
    'rounded',
    'p-4',
    'shadow-lg',
    'z-70',
    'min-w-xs',
    'max-w-full',
    {
      block: isActive,

      hidden: !isActive,
    },
  );

  return createPortal(
    <div className={modalWrapperClassName}>
      <div className={modalClassname}>{children}</div>
    </div>,
    container,
  );
};

export default Modal;
