import React from 'react';
import ColorPicker from 'rc-color-picker';
import { connect } from 'formik';

const ColorField = (props) => {
  const { formik, name } = props;
  const { setFieldValue, setFieldTouched, values } = formik;
  const value = values[name];

  return (
    <div className="input-field flex">
      <div className="pr-2 cursor-not-allowed">{value}</div>
      <ColorPicker
        color={value}
        onChange={(colors) => {
          setFieldValue(name, colors.color);
          setFieldTouched(name, true);
        }}
      />
    </div>
  );
};

export default connect(ColorField);
