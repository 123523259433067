import React from 'react';
import Html from 'slate-html-serializer';

const BLOCK_TAGS = {
  blockquote: 'quote',
  p: 'paragraph',
  ul: 'bulleted-list',
  ol: 'numbered-list',
  li: 'list-item',
  h1: 'heading-one',
  h2: 'heading-two',
  h3: 'heading-three',
};

const MARK_TAGS = {
  code: 'code',
  em: 'italic',
  strong: 'bold',
  u: 'underline',
};

const INLINE_TAGS = {
  a: 'link',
};

export const serializeBlocks = (node, children) => {
  switch (node.type) {
    case 'block-quote':
      return <blockquote>{children}</blockquote>;
    case 'bulleted-list':
      return <ul>{children}</ul>;
    case 'heading-one':
      return <h1>{children}</h1>;
    case 'heading-two':
      return <h2>{children}</h2>;
    case 'heading-three':
      return <h3>{children}</h3>;
    case 'list-item':
      return <li>{children}</li>;
    case 'numbered-list':
      return <ol>{children}</ol>;
    case 'paragraph':
      return <p>{children}</p>;
    default:
      return '';
  }
};

export const serializeInlines = (node, children) => {
  switch (node.type) {
    case 'link':
      return <a href={node.data.get('href')}>{children}</a>;
    default:
      return '';
  }
};

export const serializeMarks = (node, children) => {
  switch (node.type) {
    case 'bold':
      return <strong>{children}</strong>;
    case 'code':
      return <code>{children}</code>;
    case 'italic':
      return <em>{children}</em>;
    case 'link':
      return <a href={node.data.get('href')}>{children}</a>;
    case 'underlined':
      return <u>{children}</u>;
    default:
      return '';
  }
};

export const rules = [
  // Blocks
  {
    deserialize(el, next) {
      const type = BLOCK_TAGS[el.tagName.toLowerCase()];
      if (type) {
        return {
          object: 'block',
          type,
          data: {
            className: el.getAttribute('class'),
          },
          nodes: next(el.childNodes),
        };
      }
    },
    serialize(obj, children) {
      if (obj.object === 'block') {
        return serializeBlocks(obj, children);
      }
    },
  },
  // Marks
  {
    deserialize(el, next) {
      const type = MARK_TAGS[el.tagName.toLowerCase()];
      if (type) {
        return {
          object: 'mark',
          type,
          nodes: next(el.childNodes),
        };
      }
    },
    serialize(obj, children) {
      if (obj.object === 'mark') {
        return serializeMarks(obj, children);
      }
    },
  },
  // Inlines
  {
    deserialize(el, next) {
      const type = INLINE_TAGS[el.tagName.toLowerCase()];
      if (type) {
        return {
          object: 'inline',
          type,
          data: {
            href: el.getAttribute('href'),
          },
          nodes: next(el.childNodes),
        };
      }
    },
    serialize(obj, children) {
      if (obj.object === 'inline') {
        return serializeInlines(obj, children);
      }
    },
  },
];

// Create a new serializer instance with our `rules` from above.
export const html = new Html({ rules });
