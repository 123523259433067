import React, { useEffect, useState } from 'react';

import Bubble from '../components/Bubble';

const BubbleFrame = () => {
  const [settings, setSettings] = useState({
    appId: undefined,
    customFields: {},
    email: undefined,
    name: undefined,
  });

  const {
    appId, customFields, domain, email, name,
  } = settings;

  const handleReceiveEvent = (ev) => {
    const { data } = ev;

    if (!data || typeof data !== 'object') return;
    if (data.type !== 'init' && data.type !== 'update') return;

    const { payload } = data;

    const newSettings = {
      appId: payload.appId || appId,
      customFields: payload.customFields || customFields,
      domain: payload.domain || domain,
      email: payload.email || email,
      name: payload.name || name,
    };

    setSettings(newSettings);
  };

  useEffect(() => {
    window.addEventListener('message', handleReceiveEvent);

    return () => {
      window.removeEventListener('message', handleReceiveEvent);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId, customFields, domain, email, name]);

  if (!appId || !domain) return false;

  return (
    <Bubble
      isTesting={false}
      profileId={appId}
      customFields={customFields}
      domain={domain}
      email={email}
      name={name}
    />
  );
};

export default BubbleFrame;
