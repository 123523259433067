import React from "react";
import { Field, Form, Formik } from "formik";
import { forgotPassword } from "meteor-apollo-accounts";

import {
  FieldError,
  FormResponse,
  validate
} from "../components/FormikHelpers";

import apolloClient from "../apollo";
import { useStartRef } from "../helpers";

const ResetPassword = () => {
  const startRef = useStartRef(null);

  const resetPassword = async (values, formik) => {
    const { setStatus, setSubmitting } = formik;
    const { email } = values;

    try {
      await forgotPassword({ email }, apolloClient);
      setStatus({
        success:
          "We have send a verification link to your e-mail. Check your inbox to reset your password"
      });
    } catch (err) {
      setStatus({ err });
    }

    setSubmitting(false);
  };

  return (
    <div className="container-sm">
      <h1 className="text-center h2">Reset your password</h1>
      <div className="callout p-8">
        <Formik
          initialValues={{
            email: ""
          }}
          validate={values => {
            return validate(values, ["email"]);
          }}
          onSubmit={resetPassword}
          validateOnBlur={false}
          render={formik => {
            const { isSubmitting, isValid } = formik;

            return (
              <Form>
                <Field
                  innerRef={startRef}
                  type="email"
                  name="email"
                  placeholder="Email"
                />
                <FieldError field="email" />

                <FormResponse />

                <button
                  className="button button-primary"
                  type="submit"
                  disabled={isSubmitting || !isValid}
                >
                  Reset password
                </button>
              </Form>
            );
          }}
        />
      </div>
    </div>
  );
};

export default ResetPassword;
