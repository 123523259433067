import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';

import { UPGRADE_ACCOUNT } from '../../../../queries';

import {
  CountryField,
  FieldError,
  FormResponse,
  StripeField,
  validate,
} from '../../../../components/FormikHelpers';

const propTypes = {
  profile: PropTypes.object.isRequired,
};

const CheckoutForm = (props) => {
  const { profile } = props;

  const [upgradeAccount] = useMutation(UPGRADE_ACCOUNT);

  const handleSubmit = async (values, formik) => {
    const { resetForm, setStatus, setSubmitting } = formik;

    const { stripeToken, ...data } = values;

    try {
      await upgradeAccount({
        variables: {
          profileId: profile._id,
          data,
          stripeToken,
        },
      });
      resetForm();
      setStatus({
        success: (
          <div>
            Thanks you for upgrading to Pro!
            {' '}
            <Link to="/">Return to home</Link>
          </div>
        ),
      });
    } catch (err) {
      setStatus({ err });
    }

    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        addressLine1: profile.addressLine1 || '',
        addressLine2: profile.addressLine2 || '',
        city: profile.city || '',
        companyName: profile.companyName || '',
        country: profile.country || '',
        fullName: profile.fullName || '',
        state: profile.state || '',
        stripeToken: '',
        vatNumber: profile.vatNumber || '',
        zipCode: profile.zipCode || '',
      }}
      t
      validate={(values) => {
        return validate(values, [
          'fullName',
          'companyName',
          'addressLine1',
          'city',
          'zipCode',
          'country',
          'stripeToken',
        ]);
      }}
      onSubmit={handleSubmit}
      render={(formik) => {
        const { isSubmitting, isValid } = formik;

        return (
          <Form>
            <label htmlFor="fullName">Full Name</label>
            <Field
              type="text"
              name="fullName"
              placeholder="Full name"
              required
            />
            <FieldError field="fullName" />

            <label htmlFor="companyName">Company name</label>
            <Field
              type="text"
              name="companyName"
              placeholder="Company name"
              required
            />
            <FieldError field="companyName" />

            <label htmlFor="addressLine1">Address</label>
            <Field
              type="text"
              name="addressLine1"
              placeholder="Address line 1"
              required
            />
            <FieldError field="addressLine1" />

            <Field
              type="text"
              name="addressLine2"
              placeholder="Address line 2 (optional)"
            />
            <FieldError field="addressLine2" />

            <label htmlFor="city">City</label>
            <Field type="text" name="city" placeholder="City" required />
            <FieldError field="city" />

            <label htmlFor="state">State</label>
            <Field type="text" name="state" placeholder="State (optional)" />
            <FieldError field="state" />

            <div className="flex flex-padded">
              <div className="w-1/2">
                <label htmlFor="zipCode">ZIP / Postal Code</label>
                <Field
                  type="text"
                  name="zipCode"
                  id="zipCode"
                  placeholder="Zip / Postal Code"
                  required
                />
                <FieldError field="zipCode" />
              </div>
              <div className="w-1/2">
                <label htmlFor="country">Country</label>
                <CountryField name="country" />
                <FieldError field="country" />
              </div>
            </div>

            <label htmlFor="vatNumber">VAT Number</label>
            <Field
              type="text"
              name="vatNumber"
              placeholder="VAT number (optional)"
            />

            <label htmlFor="stripeToken">Credit or debit card</label>
            <StripeField name="stripeToken" />
            <FieldError field="stripeToken" />

            <FormResponse />

            <div className="flex items-center flex-padded">
              <div>
                <button
                  className="button button-primary"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                >
                  Complete Upgrade
                </button>
              </div>

              <div className="text-gray-500">
                SSL encrypted - Cancel anytime.
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

CheckoutForm.propTypes = propTypes;

export default CheckoutForm;
