import React from 'react';
import { Tooltip } from 'react-tippy';

import { HelpIcon } from '../Icons';

const Help = (props) => {
  const { children } = props;

  return (
    <Tooltip
      style={{
        display: 'inline-block',
        lineHeight: '1',
      }}
      html={(
        <div className="max-w-xs">
          {children}
        </div>
)}
      arrow
      popperOptions={{
        modifiers: {
          preventOverflow: {
            enabled: false,
          },
          hide: {
            enabled: false,
          },
          flip: {
            enabled: true,
          },
        },
      }}
    >
      <div
        className="inline-block align-middle text-gray-500 p-1 hover:text-gray-600 hover:bg-offwhite rounded"
      >
        <HelpIcon size={22} />
      </div>
    </Tooltip>
  );
};

export default Help;
