import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  size: PropTypes.number,
};

const defaultProps = {
  size: 24,
};

const Check = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="align-middle"
    >
      <polyline points="20 6 9 17 4 12" />
    </svg>
  );
};

Check.propTypes = propTypes;
Check.defaultProps = defaultProps;

export default Check;
